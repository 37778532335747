.dip__modal_title {
  font-family: var(--font-family);
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 1rem 0;
}

.dip__modal_delete_text {
  font-family: var(--font-family);
  font-size: 16px;
  line-height: 26px;
  color: var(--color-button);
  cursor: pointer;
  width: fit-content;
}

@media screen and (max-width: 670px) {
  .dip__modal_title {
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 24px;
  }

  .dip__modal_delete_text {
    font-size: 14px;
    line-height: 24px;
  }
}
