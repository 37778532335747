/* .dip__component-Tabs {
  width: 80%;
  height: auto;
  min-height: 400px;
  background: #053742;
  margin: 3.5rem auto 1.5rem;
  padding: 2rem 1rem;
  color: #e8f0f2;
  border-radius: 2rem;
  @media (max-width: 769px) {
    padding: 2rem 0;
  }
} */
.dip__component-Tabs {
  /* background: #053742; */
  padding: 2rem 1rem;
  /* color: #e8f0f2; */
  border-radius: 2rem;
}

/* Tab Navigation */
.dip__component-Tabs__nav {
  width: 60%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #39a2db;
  border-radius: 2rem;
  padding-left: 0px;
  @media (max-width: 768px) {
    width: 90%;
  }
}
.dip__component-Tabs__nav li {
  width: 50%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  border-radius: 0;
  /* border-bottom-left-radius: 2rem; */
  /* border-top-left-radius: 2rem; */
}
.dip__component-Tabs__nav .borderLeft {
  border-radius: 0;
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}
.dip__component-Tabs__nav .borderRight {
  border-radius: 0;
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}
/* .dip__component-Tabs__nav li:nth-child(2) {
  border-radius: 0;
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
} */
.dip__component-Tabs__nav li:hover {
  background: rgba(50, 224, 196, 0.15);
}
.dip__component-Tabs__nav li.active {
  background: #39a2db;
}

/* First and Second Tab Styles */
.FirstTab p,
.SecondTab p {
  font-size: 2rem;
  text-align: center;
}
