.dip__products {
  display: flex;
  flex-direction: row;
}

.dip__products-content {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  font-family: var(--font-family);
}

.dip__products-content__list-item {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */

  /* font styles */
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;

  background: var(--color-black);
  margin: 1rem 0;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 1rem;
}

.dip__products-content__list-item-id {
  min-width: 3rem;
  max-width: 3rem;
  margin-right: 1rem;
}

@media screen and (max-width: 850px) {
  .dip__products-content__list-item {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .dip__products-content__list-item {
    font-size: 14px;
    line-height: 24px;
  }
}
