/* body {
  font-family: "Gotham";
} */
.responsive-decan {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
}
.responsive-decan thead {
  visibility: visible;
  /* background: #666; */
  background-color: #fff;
}

@media screen and (max-width: 600px) {
  .responsive-decan thead {
    visibility: hidden;
    /* background: #fafafa; */
  }
}

.responsive-decan tr {
  display: table-row;
  padding: 5px;
  margin-bottom: 0;
  border: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.responsive-decan tr:nth-child(even) {
  /* background: #fafafa; */
  background-color: #fff;
}

@media screen and (max-width: 600px) {
  .responsive-decan tr {
    display: block;
    border-bottom-width: 2px;
    margin-bottom: 10px;
  }
}

.responsive-decan th,
.responsive-decan td {
  padding: 10px;
  text-align: left;
}
.responsive-decan th {
  text-transform: uppercase;
  font-size: 11px;
  /* color: rgb(255, 243, 9); */
  color: rgb(186, 186, 186);
}

.responsive-decan td {
  display: table-cell;
  text-align: left;
  font-size: 14px;
  border-bottom: none;
}
@media screen and (max-width: 600px) {
  .responsive-decan td {
    border-bottom: 1px dotted #ddd;
    display: block;
    text-align: right;
    font-size: 13px;
    font-weight: 600;
  }
}

.responsive-decan td:last-child {
  border-bottom: none;
}

.responsive-decan td:before {
  content: "";
  /* display: none; */
}
@media screen and (max-width: 600px) {
  .responsive-decan td:before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
  }
}

/* 
  **
    Table Designs
  **
*/

.responsive-decan td .colorContainer {
  display: flex;
  justify-content: flex-start;
}

.responsive-decan td .color {
  width: 1rem;
  height: 1rem;
}

@media screen and (max-width: 600px) {
  .responsive-decan td .colorContainer {
    justify-content: flex-end;
  }
}
