.dip__decanList-summary .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dip__decanList-summary .tank {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
}

.dip__decanList-summary .tank-label {
  font-weight: bold;
}

.dip__decanList-summary .tank-value {
  margin-top: 5px;
}
