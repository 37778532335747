.dip__decanList {
  flex: 1;
}

.dip__decanList-content {
  padding: 5px;
  margin: 2rem 0;
  border: 2px solid #ddd;
  border-radius: 10px;

  /* CSS SHADOW  */
  -webkit-box-shadow: 5px 5px 10px 0px rgba(143, 143, 143, 0.75);
  -moz-box-shadow: 5px 5px 10px 0px rgba(143, 143, 143, 0.75);
  box-shadow: 5px 5px 10px 0px rgba(143, 143, 143, 0.75);
}

.dip__decanList-content-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dip__decanList-content-header__groupB {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dip__decanList-content-header__group__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 10px 0;
  font-size: 16px;
  line-height: 24px;
}

.dip__decanList-content-header__group__item .valPara {
  margin-left: 10px;
  font-weight: 600;
}

.dip__decanList-content__btn {
  margin-right: 10px;
  /* background: var(--color-white); */
}

.dip__decanList-content__btn button {
  background: var(--color-blog);
  color: var(--color-white);
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 0 0 0;
  border-radius: 5px;

  border: none;
  outline: none;
  cursor: pointer;
  min-width: 80px;
}

.dip__decanList-updateDecan {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 6rem;
  padding-left: 6rem;
  padding-top: 4rem;
}

.dip__decanList-updateDecan h2 {
  font-family: var(--font-family);
  font-size: 18px;
  line-height: 28px;
}

@media screen and (max-width: 600px) {
  .dip__decanList-content-header {
    flex-direction: column;
    align-items: unset;
  }

  .dip__decanList-content-header__groupB {
    flex-direction: column;
    align-items: unset;
  }

  .dip__decanList-content-header__group__item {
    font-size: 14px;
    line-height: 22px;
  }

  .dip__decanList-content__btn {
    margin-right: 0px;
  }

  .dip__decanList-content__btn button {
    font-size: 14px;
    line-height: 22px;
    padding: 5px 0 0 0;

    width: 100%;
  }

  .dip__decanList-updateDecan {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .dip__decanList-updateDecan h2 {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 26px;
  }
}
