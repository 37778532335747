.dip__newdecan {
  display: flex;
  flex-direction: row;
}

.dip__newdecan-content {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  font-family: var(--font-family);
}

.dip__newdecan-content__header {
  width: 100%;
  margin: 1rem 0;

  /* display: flex; */
  /* flex-direction: row; */
  display: flex;
  justify-content: space-between;
}

.dip__newdecan-content__header-child {
  margin: 5px;
}

.dip__newdecan-content__header label {
  /* font-size: 17px; */
  /* font-weight: 500; */
  /* margin-right: 20px; */
  /* align-self: center; */
  /* flex: 1; */
  /* letter-spacing: normal; */
  /* text-transform: none; */

  /* color: var(--color-white); */
  /* font-weight: 600; */
  letter-spacing: 0.43px;
  font-size: 16px;
  line-height: 26px;
  /* text-transform: uppercase; */
  /* display: block; */
  /* font-family: var(--font-family); */
}

.dip__newdecan-content__header input {
  /* flex: 2; */
  width: 100%;
  min-height: 35px;

  /* min-height: 50px; */
  /* font-family: var(--font-family); */
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* background: var(--color-footer); */
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  /* color: var(--color-white); */

  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.dip__newdecan-tankEntry {
  display: flex;
  flex-direction: row;
  padding: 0 0.5rem;
  margin: 0.5rem 0;
}

.dip__newdecan-tankEntry__group1 {
  display: flex;
  flex-direction: row;
  flex: 1.5;
}

.dip__newdecan-tankEntry__group2 {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.dip__newdecan-tankEntry__groupContent-tankNameContainer {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

.dip__newdecan-tankEntry__groupContent-moreInfo {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  justify-content: flex-end;
}

.dip__newdecan-tankEntry__groupContent-diffContainer {
  display: flex;
  flex: 1;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
}

.dip__newdecan-input {
  width: 100%;
  /* margin: 1rem 1rem 0 0; */
  margin: 0 1rem 0 0;

  display: flex;
  flex-direction: column;
}

.dip__newdecan-input label {
  /* align-self: center; */
  /* flex: 1; */

  /* color: var(--color-white); */
  /* font-weight: 600; */
  font-size: 16px;
  line-height: 26px;
  /* text-transform: uppercase; */
  /* display: block; */
  /* font-family: var(--font-family); */
}

.dip__newdecan-input p {
  /* flex: 1; */
  /* align-self: center; */
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  /* padding: 0 1rem; */
}

.dip__newdecan-input input {
  flex: 2;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* background: var(--color-footer); */
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  color: var(--color-white);

  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

@media screen and (max-width: 850px) {
  .dip__newdecan-content__header {
    flex-direction: column;
  }

  .dip__newdecan-tankEntry__group1 {
    flex-direction: column;
    margin-right: 1rem;
    flex: 1;
  }

  .dip__newdecan-tankEntry__group2 {
    flex-direction: column;
    flex: 1;
  }

  .dip__newdecan-input {
    flex: 1;
  }

  .dip__newdecan-tankEntry {
    flex-direction: row;
  }
  .dip__newdecan-tankEntry__groupContent-tankNameContainer {
    flex-direction: row;
    justify-content: space-between;
  }

  .dip__newdecan-input label {
    font-size: 14px;
    font-weight: 500;
    /* letter-spacing: normal; */
    letter-spacing: 0.43px;
    line-height: 22px;
    text-transform: uppercase;
    align-self: flex-start;
    /* padding: 0 1rem; */
  }
  .dip__newdecan-input p {
    /* align-self: center; */
    /* font-weight: 600; */
    font-size: 16px;
    line-height: 22px;
  }
}

@media screen and (max-width: 430px) {
  .dip__newdecan-input label {
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    align-self: flex-start;
    /* padding: 0 1rem; */
  }

  .dip__newdecan-input p {
    /* align-self: center; */
    /* font-weight: 600; */
    font-size: 14px;
    line-height: 20px;
    /* padding: 0 1rem; */
  }

  .dip__newdecan-tankEntry__groupContent-moreInfo {
    /* flex-direction: column; */
    /* align-items: right; */
    justify-content: flex-end;
  }
}
