.dip__component-input {
  width: 100%;
  margin: 1rem 0;

  display: flex;
  flex-direction: row;
}

.dip__component-input label {
  /* font-size: 17px; */
  /* font-weight: 500; */
  margin-right: 20px;
  align-self: center;
  flex: 1;
  /* letter-spacing: normal; */
  text-transform: none;

  color: var(--color-white);
  font-weight: 600;
  letter-spacing: 0.43px;
  font-size: 16px;
  line-height: 26px;
  /* text-transform: uppercase; */
  display: block;
  font-family: var(--font-family);
}

.dip__component-input input,
.dip__component-input select {
  flex: 2;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  /* background: var(--color-footer); */
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  color: var(--color-white);

  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.dip__component-input p {
  flex: 2;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  /* background: var(--color-footer); */
  /* border: 2px solid var(--color-footer); */
  padding: 0 1rem;
  outline: none;
  color: var(--color-white);
}

@media screen and (max-width: 670px) {
  .dip__component-input {
    flex-direction: column;
  }

  .dip__component-input label {
    font-size: 12px;
    font-weight: 500;
    /* letter-spacing: normal; */
    letter-spacing: 0.43px;
    line-height: 20px;
    text-transform: uppercase;
    align-self: flex-start;
    padding: 0 1rem;
  }

  .dip__component-input input,
  .dip__component-input select {
    font-size: 16px;
    line-height: 20px;
    min-height: 40px;
  }
}
