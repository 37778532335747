.dip__tanks {
  display: flex;
  flex-direction: row;
}

.dip__tanks-content {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  font-family: var(--font-family);
}
