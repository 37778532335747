/* body {
  font-family: "Gotham";
} */
.responsive-table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
}
.responsive-table thead {
  visibility: hidden;
  background: #fafafa;
}
@media screen and (min-width: 600px) {
  .responsive-table thead {
    visibility: visible;
  }
}
.responsive-table tr {
  border: 1px solid #ddd;
  border-bottom: 2px solid #ddd;
  padding: 5px;
  margin-bottom: 10px;
  display: block;
}
@media screen and (min-width: 600px) {
  .responsive-table tr {
    display: table-row;
    border-bottom-width: 1px;
    margin-bottom: 0;
  }
  .responsive-table tr:nth-child(even) {
    background: #fafafa;
  }
}
.responsive-table th,
.responsive-table td {
  padding: 10px;
  text-align: left;
}
.responsive-table th {
  text-transform: uppercase;
  font-size: 11px;
}
.responsive-table td {
  display: block;
  text-align: right;
  font-size: 13px;
  border-bottom: 1px dotted #ddd;
}
.responsive-table td:last-child {
  border-bottom: none;
}
@media screen and (min-width: 600px) {
  .responsive-table td {
    display: table-cell;
    text-align: left;
    font-size: 14px;
    border-bottom: none;
  }
}
.responsive-table td:before {
  content: attr(data-label);
  float: left;
  text-transform: uppercase;
  font-weight: bold;
}
@media screen and (min-width: 600px) {
  .responsive-table td:before {
    content: "";
    display: none;
  }
}

/* 
    **
      Table Designs
    **
  */

.responsive-table td .colorContainer {
  display: flex;
  justify-content: flex-start;
}

.responsive-table td .color {
  width: 1rem;
  height: 1rem;
}

@media screen and (max-width: 600px) {
  .responsive-table td .colorContainer {
    justify-content: flex-end;
  }
}
