.dip__component-button {
  display: flex;
  flex-direction: column;
  margin: 0 0 2rem 0;
}

.dip__component-button button {
  padding: 0.5rem 1rem;
  color: var(--color-black);
  background: var(--color-button);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  align-self: center;
}

@media screen and (max-width: 850px) {
  .dip__component-button button {
    font-size: 14px;
    line-height: 25px;
  }
}

@media screen and (max-width: 490px) {
  .dip__component-button button {
    font-size: 12px;
    line-height: 20px;
  }
}
